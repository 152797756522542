* {
  box-sizing: border-box;
  margin: 0;
}

:root {
  --heights: 35vw;
  --widths: 100%;
  --slideContentDelay: 0.3s;
}

.slider-container {
  height: var(--heights);
  width: var(--widths);
  position: relative;
  margin: auto;
  overflow: hidden;
  /* background-color: rgba(24, 24, 24, 0.2); */
}

.active {
  opacity: 1;
  /* transform: scale(1); */
}

.inactive {
  /* opacity: 0; */
  /* transform: scale(0.3); */
}

.slides {
  height: var(--heights);
  width: var(--widths);
  position: absolute;
  transition: all 1s ease-in-out;
}

.slide-image {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}

.slide-title,
.slide-text {
  width: 100%;
  height: 100%;
  color: white;
  font-size: 50px;
  position: absolute;
  text-align: center;
  top: 40%;
  z-index: 10;
}

.slide-text {
  top: 65%;
  font-size: 2rem;
}

.prev,
.next {
  cursor: pointer;
  z-index: 100;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 1rem;
  margin-top: -3rem;
  font-size: 40px;
  font-weight: bold;
  border-radius: 0px 5px 5px 0px;
}

.prev:hover,
.next:hover {
  color: white;
  transition: all 0.5s ease-in;
}

.next {
  right: 0;
  border-radius: 5px 0px 0px 5px;
}

.all-dots {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  top: 85%;
  justify-content: center;
  z-index: 200;
}

.dot {
  cursor: pointer;
  height: 1.5rem;
  width: 1.5rem;
  margin: 0px 3px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  display: inline-block;
}

.active-dot,
.dot:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.active .slider-content {
  width: 100%;
  height: 100%;
  color: white;
  font-size: 50px;
  position: absolute;
  text-align: center;
  top: 0%;
  z-index: 10;
}

#slider-gunabrosLogo {
  position: absolute;
  top: 6vw;
  left: 35.5vw;
  height: 10.5vw;
}

#main-slide-text {
  position: absolute;
  top: 16.5vw;
  left: 30vw;
  text-shadow: rgb(0 0 0) 2px 2px 3px;
  text-align: center;
  font-size: 2.5vw;
  color: rgb(255, 255, 255);
  white-space: nowrap;
  width: auto;
  height: auto;
  padding: 0px;
  border-width: 0px;
  margin-left: 0px;
  margin-top: 1vw;
  opacity: 1;
  filter: none;    
  font-weight: 300;
  font-family: "Roboto", sans-serif;
}

.slider-content .linkButton {
  top: 25.5vw;
  left: 42vw;
  position: absolute;
  border: none;
  background-color: #0088cc;
  border-radius: 0.13vw;
  /* padding: 9px 14px; */
  font-size: 0.98vw;
  line-height: normal;
  color: #ffffff;
  text-shadow: 0 -0.065vw 0 rgb(0 0 0 / 25%);
  padding: 1.043vw 2.35vw 1.17vw 2.35vw;
  letter-spacing: 0.065vw;
}

#second-main-slide-text, #second-main-slide-text2 {
  top: 12vw;
  left: 9vw;
  position: absolute;
  color: rgb(255, 255, 255);
  white-space: nowrap;
  filter: none;
  width: auto;
  height: auto;
  padding: 0px;
  border-width: 0px;
  margin-left: 0px;
  margin-top: 0px;
  opacity: 1;
  font-size: 2.3vw;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
}

#second-main-slide-text2 {
  top: 15vw;
}

#second-main-slide-link {
  position: absolute;
  top: 19vw;
  left: 9vw;
}

.slides.inactive {
  transform: scale(0.3);
  opacity: 0;
  z-index: -1;
}

/* Slide content animations */

.slides.active #slider-gunabrosLogo {
  opacity: 0;
  animation: transitionInLogo 1.5s;
  animation-delay: var(--slideContentDelay);
  animation-fill-mode: forwards;
}

/* Onload transitions */

@keyframes transitionInLogo {
  from {
      opacity: 0;
      transform: translateX(50%);
  }

  to {
      opacity: 1;
      transform: translateX(0);
  }
  
}


.slides.active #main-slide-text {
  opacity: 0;
  animation: transitionInText 1.5s;
  animation-delay: var(--slideContentDelay);
  animation-fill-mode: forwards;
}

/* Onload transitions */

@keyframes transitionInText {
  from {
      opacity: 0;
      transform: translateY(-50%);
  }

  to {
      opacity: 1;
      transform: translateY(0);
  }
  
}


.slides.active #firstSlide .linkButton {
  opacity: 0;
  animation: transitionInButton 1.5s;
  animation-delay: var(--slideContentDelay);
  animation-fill-mode: forwards;
}

/* Onload transitions */

@keyframes transitionInButton {
  from {
      opacity: 0;
      transform: translateY(50%);
  }

  to {
      opacity: 1;
      transform: translateY(0);
  }
  
}


.slides.active #secondSlide h2 {
  opacity: 0;
  animation: transitionInSubText 1.5s;
  animation-delay: var(--slideContentDelay);
  animation-fill-mode: forwards;
}

/* Onload transitions */

@keyframes transitionInSubText {
  from {
      opacity: 0;
      transform: translateX(-50%);
  }

  to {
      opacity: 1;
      transform: translateX(0);
  }
  
}


.slides.active #secondSlide .linkButton {
  opacity: 0;
  animation: transitionInSubButton 1.5s;
  animation-delay: var(--slideContentDelay);
  animation-fill-mode: forwards;
}

/* Onload transitions */

@keyframes transitionInSubButton {
  from {
      opacity: 0;
      transform: translateX(50%);
  }

  to {
      opacity: 1;
      transform: translateX(0);
  }
  
}

.slides.active .linkButton:hover {
  background-color: rgba(84, 114, 210, 0.6);
}